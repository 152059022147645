<template>
  <div class="otp-boxs mt-4 position-relative">
  <h6 class="mb-2">{{$t('Enter OTP')}}</h6>
  <form method="get" class="digit-group" data-group-name="digits" data-autosubmit="false" autocomplete="off">
    <input type="text" id="digit-1" name="digit-1" data-next="digit-2" maxlength="1" autofocus="autofocus" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
    <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
    <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /><span class="splitter">&ndash;</span>
    <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
    <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
    <input type="text" id="digit-6" name="digit-6" data-previous="digit-5" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
  </form>
  {{(countDown > 0)? "00:"+("0" + countDown).slice(-2):''}}
  <div id="recaptchacontainer"></div>
  <div class="col-md-12 text-end resend btn" v-if="resendpass && countDown == 0 && !is_email_field" @click="resendCode()" >{{$t('Resend')}}</div>
  <div class="full-btn mt-4" v-if="showOtp">
    <button v-if="showOtp" @click="verifyOTP">{{$t('Submit')}}</button>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
import firebase from "firebase";

export default {  
  props:['mobile_number','is_email_field','country_code','email','userId'],
data() {
  return {
    countDown : 30,    
    appVerifier:"",
    showOtp:true,
    resendpass:false,

  };
},
mounted() {
  this.countDownTimer();
},
methods: {
  ...mapActions("user",["forgotPasswordVerifyOTP"]),

  countDownTimer() {
    var timerId = setInterval(()=>{
      if (this.countDown == 0) {
        clearTimeout(timerId);
        this.resendpass= true;
        } else {
          this.countDown = this.countDown;
          this.countDown--;
        }
    }, 1000);
  },

  resendCode(){
    if (
        (this.$auth.setting) &&
        (this.$auth.setting.firebase_apiKey && this.$auth.setting.firebase_apiKey != '') &&
        (this.$auth.setting.firebase_authDomain && this.$auth.setting.firebase_authDomain != '') &&
        (this.$auth.setting.firebase_databaseURL && this.$auth.setting.firebase_databaseURL != '') &&
        (this.$auth.setting.firebase_projectId && this.$auth.setting.firebase_projectId != '') &&
        (this.$auth.setting.firebase_storageBucket && this.$auth.setting.firebase_storageBucket != '') &&
        (this.$auth.setting.firebase_messagingSenderId && this.$auth.setting.firebase_messagingSenderId != '') &&
        (this.$auth.setting.firebase_appId && this.$auth.setting.firebase_appId != '')
      ) {
        this.initFirebase1();
      }    
    this.resendpass= false;
    this.countDown = 30;    
    this.countDownTimer();    
    this.appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber("+" + this.country_code + this.mobile_number,this.appVerifier).then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      this.showOtp = true;
      localStorage.setItem('mobile_number',this.mobile_number);
      localStorage.setItem('country_code', "+" + this.country_code);
      setTimeout(() => this.otpJs(), 1000);
      this.checkMobile = false;      
    }).catch((error) => {
      this.resendpass= true;
      if(error.code == 'auth/invalid-phone-number'){
        this.$toasted.error(this.$t('Invalid Phone Number'), { duration: 2000 });
      }else if(error.code == 'auth/too-many-requests'){
        this.$toasted.error(this.$t('Too many times same mobile number used'), { duration: 2000 });
      }else{
        this.$toasted.error(this.$t('Something went wrong..!!!'), { duration: 2000 });
      }
    });
  },

  initFirebase1() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptchacontainer',
      {
        size: 'invisible',
        callback: function (response) {            
        },
      }
    );
    recaptchaVerifier.render().then(function(widgetId) {
        window.recaptchaWidgetId = widgetId;
    });
  },

  verifyOTP(e) {    
    e.preventDefault();    
    let vm = this;
    var code1 = document.getElementById("digit-1").value;    
    if(code1 != '') {      
      if(this.mobile_number && this.mobile_number != '') {
        window.confirmationResult.confirm(this.getCode()).then(function (el) {
          vm.$emit("successOTP","yes");
        }).catch(function (err) {          
          if(err.code == 'auth/invalid-verification-code'){
            vm.$toasted.error('Invalid OTP', { duration: 2000 });
          }
          if(err.code == '400'){
            vm.$toasted.error('Session Expired', { duration: 2000 });
          }
        });
      }else if(this.email && this.email != '') {        
        this.forgotPasswordVerifyOTP({
          vendor_id: this.$auth.getVendorId(),
          user_email: this.email,
          is_langauge: this.$store.state.lang.locale,
          user_id:this.userId,
          otp:this.getCode(),
        }).then((response) => {
          if (response.code == 200) {
            // this.$v.$reset();
            // this.showPassword = true;
            // this.checkMobile = false;
            // this.showOtp = false;            
            localStorage.setItem('email',this.email);            
            this.$toasted.success(response.msg, { duration: 2000 });
            this.$emit("successOTP","yes");
          }else{
            this.$toasted.error(this.$t(response.msg), { duration: 2000 });
          }
        });
      }
    }else{
      this.$toasted.error(this.$t('Verification OTP Required'), { duration: 2000 });
    }
  },

  getCode() {
    var code1 = document.getElementById("digit-1").value;
    var code2 = document.getElementById("digit-2").value;
    var code3 = document.getElementById("digit-3").value;
    var code4 = document.getElementById("digit-4").value;
    var code5 = document.getElementById("digit-5").value;
    var code6 = document.getElementById("digit-6").value;
    return (code1 + "" + code2 + "" + code3 + "" + code4 + "" + code5 + "" + code6);
  },
  
  otpJs() {
    window.$(".digit-group").find("input").each(function () {
      window.$(this).attr("maxlength", 1);
      window.$(this).on("keyup", function (e) {
        var parent = window.$(window.$(this).parent());
        if (e.keyCode === 8 || e.keyCode === 37) {
          var prev = parent.find("input#" + window.jQuery(this).data("previous"));
          if (prev.length) {
              window.jQuery(prev).select();
            }
          } else if (((e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 49 && e.keyCode <= 57) ||  e.keyCode === 39 || e.keyCode == 229) ) {
            var next = parent.find("input#" + window.jQuery(this).data("next"));
            if (next.length) {
              window.jQuery(next).select();
            } else {
              if (parent.data("autosubmit")) {
                parent.submit();
              }
            }
          }
          if(e.keyCode === 9){
            var prev = parent.find("input#" + window.jQuery(this).data("previous"));
            if (prev.length) {
              window.jQuery(prev).select();
            }
          }
      });
    });
  }
  
}
}
</script>