<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="login-start-main box-flex">
      <div class="container login-container review-main text-capitalize position-relative box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card | d-flex flex-column">
              <div class="center-card-box | p-3 border-bottom">
                <div class="sign-top">
                  <h4 >{{$t('Forgot Password')}}</h4>
                </div>
              </div>
              <div class="center-card-box">
                <div class="sign-main">
                  <div class="sign-top plr15 mt-3" v-if="$auth.getAppLogo() != ''">
                    <img :src="$auth.getAppLogo()" class="sign-main-logo" />                    
                  </div>
                  <div class="plr15">
                    <div class="mt-4 position-relative" :class="is_mobile_field ? 'number-boxs':''" v-if="checkMobile">
                      <h6 class="mb-2">{{$t('Please enter mobile number or email address')}}</h6>
                      <div class="position-relative">
                        <vue-country-code v-if="is_mobile_field"  :enabledCountryCode="true" :enableSearchField="true" :defaultCountry="country_code" @onSelect="onSelect"></vue-country-code>                        
                        <input class="form-control mb-0 h50" type="tel" v-model="$v.commoninput.$model" :placeholder="$t('Mobile Number or Email Address')" aria-label="phone number" :class="{ 'is-invalid': $v.commoninput.$error }" @keyup.enter="checkNumber" />
                      </div>
                      <div v-if="$v.mobile_number.$error" class="invalid-feedback">
                        <span v-if="!$v.mobile_number.required">{{$t('Please enter phone number')}}</span>
                      </div>
                      <div id="recaptcha-container"></div>
                    </div>
                    <!-- <div class="number-boxs mt-4 position-relative numbers-box" v-if="!showPassword != true" >
                      <h6 class="mb-2">{{$t('Reset Password')}}</h6>
                      <div class="form-group position-relative rst-pass-cls">
                        <i class="fas fa-lock"></i>
                        <input class="form-control mb-0 h50" :type="show ? 'text' : 'password'" v-model="$v.newPassword.$model" :placeholder="$t('New Password')" aria-label="Password" @keyup.enter="LogIn" :class="{ 'is-invalid': $v.newPassword.$error }" />
                        <div class="eyeButton" @click="eyepassword('show')"><i v-if="show" class="fas fa-eye-slash"></i><i v-else class="fas fa-eye"></i></div>
                        <div v-if="$v.newPassword.$error" class="invalid-feedback">
                          <span v-if="!$v.newPassword.required">{{$t('Please enter New password')}}</span>
                        </div>
                      </div>
                      <div class="form-group position-relative rst-pass-cls">
                        <i class="fas fa-lock"></i>
                        <input class="form-control mb-0 h50" :type="cshow ? 'text' : 'password'" v-model="$v.repeatPassword.$model" :placeholder="$t('Confirm Password')" aria-label="Password" @keyup.enter="LogIn" :class="{ 'is-invalid': $v.repeatPassword.$error }" />
                        <div class="eyeButton" @click="eyepassword('cshow')"><i v-if="cshow" class="fas fa-eye-slash"></i><i v-else class="fas fa-eye"></i></div>
                        <div v-if="$v.repeatPassword.$error" class="invalid-feedback">
                          <span v-if="!$v.repeatPassword.required">{{$t('Please enter Repeat Password')}}</span>
                          <span v-else-if="!$v.repeatPassword.sameAsPassword">
                              {{$t('Passwords must match')}}
                          </span>
                        </div>
                      </div>
                    </div> -->
                    <ResetPassword  v-if="showPassword" :commoninput = "commoninput" ></ResetPassword>
                    <OTP v-if="showOtp" :mobile_number = "mobile_number" :email="email" :userId="userId" :is_email_field="is_email_field" :country_code="country_code" v-on:successOTP="verifyOTP"></OTP>
                    <!--<div class="otp-boxs mt-4 position-relative" v-if="showOtp == true">                      
                       <h6 class="mb-2">{{$t('Enter OTP')}}</h6>
                      <form method="get" class="digit-group" data-group-name="digits" data-autosubmit="false" autocomplete="off">
                        <input type="text" id="digit-1" name="digit-1" data-next="digit-2" maxlength="1" autofocus="autofocus" oninput="this.value=this.value.replace(/[^0-9]/g,'');"/>
                        <input type="text" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                        <input type="text" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" /><span class="splitter">&ndash;</span>
                        <input type="text" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                        <input type="text" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                        <input type="text" id="digit-6" name="digit-6" data-previous="digit-5" maxlength="1" oninput="this.value=this.value.replace(/[^0-9]/g,'');" />
                      </form>
                      {{(countDown > 0)? "00:"+("0" + countDown).slice(-2):''}}
                      <div id="recaptchacontainer"></div>
                      <div class="col-md-12 text-end resend btn" v-if="resendpass && countDown == 0 && !is_email_field" @click="resendCode()" >{{$t('Resend')}}</div> 
                    </div>-->
                    
                    <div class="full-btn mt-4" v-if="checkMobile">
                      <button @click="checkNumber" v-if="checkMobile">{{$t('Next')}}</button>
                    </div>
                    <!-- <div class="full-btn mt-4" v-if="showPassword">
                      <button @click="updatePassword" v-if="showPassword">{{$t('Submit')}}</button>
                    </div> -->
                    <!-- <div class="full-btn mt-4" v-if="showOtp">
                      <button v-if="showOtp" @click="verifyOTP">{{$t('Submit')}}</button>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import NavBar from "@/components/NavBar.vue";
import OTP from "@/components/otp.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import Login from "../apis/login";
import firebase from "firebase";


export default {
  components: {
    NavBar,
    OTP,
    ResetPassword
  },
  data() {
    return {
      mobile_number: "",
      checkMobile: true,
      checksNumber: "",
      showPassword: false,
      showOtp: false,      
      repeatPassword:"",
      logins: "",
      country_code: "",
      setting: {},
      prevRoute: null,
      countDown : 60,
      resendpass:false,
      recaptcha:true,
      appVerifier:"",
      commoninput:"",
      is_mobile_field:true,
      is_email_field:false,
      email:"",
      userId:'',
      // show:false,
      // cshow:false
    };
  },
  validations: {
    // newPassword: {
    //   required,
    // },
    // repeatPassword: {
    //   required,
    //   sameAsPassword: sameAs("newPassword"),
    // },
    mobile_number: {
      required,
    },
    commoninput: {
      required,
    },

  },

  beforeRouteEnter(to, from, next) {   
    next(vm => {
      vm.prevRoute = from
    })
  },

  mounted() {    
    if(this.setting.country_code){
      this.country_code = this.setting.country_code; 
    }else{
      let cntrycode = localStorage.getItem('cntrycode');
      this.country_code = this.base64_decode(cntrycode) 
    }
    if(localStorage.getItem('email')){
      this.is_email_field = true;
      this.is_mobile_field= false;
      this.commoninput = localStorage.getItem('email');
      this.email = this.commoninput;
    }else{
      this.is_mobile_field= true;
      this.is_email_field = false;
      this.commoninput = localStorage.getItem('mobile_number');
      this.mobile_number = this.commoninput;      
    }
  },

  watch: {
    mobile_number:function(){
      if (!/[0-9]/.test(this.mobile_number)) {
        this.mobile_number = '';
      }
    },
    commoninput:function(){
      if(this.isNumeric(this.commoninput)){
        this.mobile_number = this.commoninput;
        this.is_mobile_field= true;
        this.is_email_field = false;
      }else{
        this.is_email_field = true;
        this.is_mobile_field= false;
        this.email = this.commoninput;
      }
    }
  },

  methods: {
    ...mapActions("user",["checkMobileNumber","customerForgotPasswordData"]),
        
    onSelect({ dialCode }) {
      this.country_code = dialCode;
    },

    checkNumber() {
      if(this.mobile_number && this.mobile_number != '' && this.is_mobile_field) {
        this.$v.$touch();
        if (
            (this.$auth.setting) &&
            (this.$auth.setting.firebase_apiKey && this.$auth.setting.firebase_apiKey != '') &&
            (this.$auth.setting.firebase_authDomain && this.$auth.setting.firebase_authDomain != '') &&
            (this.$auth.setting.firebase_databaseURL && this.$auth.setting.firebase_databaseURL != '') &&
            (this.$auth.setting.firebase_projectId && this.$auth.setting.firebase_projectId != '') &&
            (this.$auth.setting.firebase_storageBucket && this.$auth.setting.firebase_storageBucket != '') &&
            (this.$auth.setting.firebase_messagingSenderId && this.$auth.setting.firebase_messagingSenderId != '') &&
            (this.$auth.setting.firebase_appId && this.$auth.setting.firebase_appId != '')
          ) {
            this.initFirebase();
          }
        if (this.$v.mobile_number.$invalid) {
          return;
        } else {
          Login.CheckMobileNumber({
            vendor_id: this.$auth.getVendorId(),
            user_role: "1",
            mobile_number: this.mobile_number,
            is_langauge: this.$store.state.lang.locale,
          }).then((response) => {
            this.checksNumber = response.data;
            if (response.data.code == 200) {
              if (this.mobile_number.length != 10) {
                this.$toasted.error(this.$t("Mobile number invalid"), { duration: 2000 });
              } else {
                this.appVerifier = window.recaptchaVerifier;
                firebase.auth().signInWithPhoneNumber(
                  "+" + this.country_code + this.mobile_number,
                  this.appVerifier
                ).then((confirmationResult) => {
                  window.confirmationResult = confirmationResult;
                  this.showOtp = true;                  
                  localStorage.setItem('mobile_number',this.mobile_number);
                  localStorage.setItem('country_code', "+" + this.country_code);
                  setTimeout(() => this.otpJs(), 1000);
                  this.checkMobile = false;
                  this.showPassword = false;
                }).catch((error) => {
                  this.resendpass= true;
                  if(error.code == 'auth/invalid-phone-number'){
                    this.$toasted.error(this.$t('Invalid Phone Number'), { duration: 2000 });
                  }else if(error.code == 'auth/too-many-requests'){
                    this.$toasted.error(this.$t('Too many times same mobile number used'), { duration: 2000 });
                  }else{
                    this.$toasted.error(this.$t('Something went wrong..!!!'), { duration: 2000 });
                  }
                });
              }
            } else {
              this.$toasted.error(this.$t('Mobile Number is not exist.'), { duration: 2000 });
            }
          });
        }
      }else if(this.email && this.email != '' && this.is_email_field){
        this.customerForgotPasswordData({
          vendor_id: this.$auth.getVendorId(),
          user_email: this.email,
          is_langauge: this.$store.state.lang.locale,            
        }).then((response) => {
          if (response.code == 200) {
            this.userId = response.user_id;
            this.showOtp = true;            
            this.checkMobile = false;
            this.showPassword = false;
            localStorage.setItem('email',this.email);
            this.$toasted.success(response.msg, { duration: 2000 }); 
          }else{
            this.$toasted.error(this.$t(response.msg), { duration: 2000 });
          }
        });
      }          
    },
    
    initFirebase() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: function (response) {            
          },
          'expired-callback': () => {
          // Reset reCAPTCHA?
          },
        }
      );
    },

    verifyOTP(val) {
      this.$v.$reset();
      this.showPassword = true;
      this.checkMobile = false;
      this.showOtp = false;
    },
    
    // updatePassword() {
    //   this.$v.$touch();
    //   if (this.$v.newPassword.$invalid && this.$v.newPassword.$invalid) {
    //       return;
    //   } else {
    //       Login.customerChangePassword({
    //           mobile_number: this.commoninput,
    //           password: this.newPassword,
    //           vendor_id:this.$auth.getVendorId(),
    //           is_langauge: this.$store.state.lang.locale,
    //       }).then((data) => {
    //           if (data.data.code == 200) {
    //               this.$v.$reset();
    //               this.$toasted.success(data.data.msg, { duration: 2000 });
    //               this.$router.push({ name: 'sign-in' });
    //           } else {
    //               this.$toasted.error(data.data.msg, { duration: 2000 });
    //           }
    //       });
    //   }
    // },

    otpJs() {
      window.$(".digit-group").find("input").each(function () {
        window.$(this).attr("maxlength", 1);
        window.$(this).on("keyup", function (e) {
          var parent = window.$(window.$(this).parent());
          if (e.keyCode === 8 || e.keyCode === 37) {
            var prev = parent.find("input#" + window.jQuery(this).data("previous"));
            if (prev.length) {
                window.jQuery(prev).select();
              }
            } else if (((e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 49 && e.keyCode <= 57) ||  e.keyCode === 39 || e.keyCode == 229) ) {
              var next = parent.find("input#" + window.jQuery(this).data("next"));
              if (next.length) {
                window.jQuery(next).select();
              } else {
                if (parent.data("autosubmit")) {
                  parent.submit();
                }
              }
            }
            if(e.keyCode === 9){
              var prev = parent.find("input#" + window.jQuery(this).data("previous"));
              if (prev.length) {
                window.jQuery(prev).select();
              }
            }
        });
      });
    },

    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    
    // eyepassword(value){
    //   if(value == 'show'){
    //     if(this.show){
    //       this.show = false;
    //     } else {
    //       this.show = true;
    //     }
    //   }else{
    //     if(this.cshow){
    //       this.cshow = false;
    //     } else {
    //       this.cshow = true;
    //     }
    //   }
      
    // },

  },
};
</script>